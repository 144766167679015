import { format } from 'date-fns';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const CareerGrid = ({ typeToShow }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulCareersJobPosting(
          sort: { fields: publishedDate, order: DESC }
        ) {
          edges {
            node {
              type
              title
              id
              slug
              publishedDate
              excerpt {
                childMarkdownRemark {
                  excerpt(pruneLength: 150)
                }
              }
            }
          }
        }
      }
    `
  );
  const [hover, setHover] = useState(false);
  return (
    <div>
      <div className='columns is-multiline'>
        {data.allContentfulCareersJobPosting.edges &&
          data.allContentfulCareersJobPosting.edges.map(edge =>
            typeToShow === 'All' ? (
              <div className='column is-full'>
                <Accordion className='accordian' allowZeroExpanded>
                  <AccordionItem
                    key={edge.node.id}
                    style={{ borderTop: '0px' }}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton
                        style={{
                          height: '100px',
                          backgroundColor: '#fff',
                          borderBottom: '1px solid #c1c1c1',
                          color: '#7b7b7b',
                          fontFamily: 'Muli',
                          marginTop: '30px',
                          fontSize: '20px',
                        }}
                      >
                        {edge.node.title}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel
                      onMouseEnter={() => {
                        setHover(true);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                      }}
                      style={
                        hover
                          ? {
                              boxShadow:
                                ' 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                              transition:
                                'all 0.3s cubic-bezier(.25, .8, .25, 1)',
                            }
                          : {
                              boxShadow:
                                '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                            }
                      }
                    >
                      <time>
                        <span style={{ fontFamily: 'Muli' }}>
                          Posted on {'  '}
                          {format(
                            new Date(edge.node.publishedDate),
                            'iii do MMM, yyyy'
                          )}
                        </span>
                      </time>
                      <p
                        style={{
                          marginTop: '25px',
                          fontFamily: 'Muli',
                          fontSize: '20px',
                        }}
                      >
                        {edge.node.excerpt.childMarkdownRemark.excerpt}
                      </p>

                      <Link
                        to={`/careers/${edge.node.slug}/`}
                        style={{ marginTop: '50px' }}
                      >
                        <button
                          className='button'
                          style={{ marginTop: '50px' }}
                        >
                          {' '}
                          <span className='button-text'>Know More</span>
                        </button>
                      </Link>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            ) : typeToShow === edge.node.type ? (
              <div className='column is-full'>
                <Accordion className='accordian' allowZeroExpanded>
                  <AccordionItem
                    key={edge.node.id}
                    style={{ borderTop: '0px' }}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton
                        style={{
                          height: '100px',
                          backgroundColor: '#fff',
                          borderBottom: '1px solid #c1c1c1',
                          color: '#7b7b7b',
                          fontFamily: 'Muli',
                          marginTop: '30px',
                          fontSize: '20px',
                        }}
                      >
                        {edge.node.title}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel
                      onMouseEnter={() => {
                        setHover(true);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                      }}
                      style={
                        hover
                          ? {
                              boxShadow:
                                ' 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                              transition:
                                'all 0.3s cubic-bezier(.25, .8, .25, 1)',
                            }
                          : {
                              boxShadow:
                                '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                            }
                      }
                    >
                      <time>
                        <span style={{ fontFamily: 'Muli' }}>
                          Posted on {'  '}
                          {format(
                            new Date(edge.node.publishedDate),
                            'iii do MMM, yyyy'
                          )}
                        </span>
                      </time>
                      <p
                        style={{
                          marginTop: '25px',
                          fontFamily: 'Muli',
                          fontSize: '20px',
                        }}
                      >
                        {edge.node.excerpt.childMarkdownRemark.excerpt}
                      </p>

                      <Link
                        to={`/careers/${edge.node.slug}/`}
                        style={{ marginTop: '50px' }}
                      >
                        <button
                          className='button'
                          style={{ marginTop: '50px' }}
                        >
                          {' '}
                          <span className='button-text'>Know More</span>
                        </button>
                      </Link>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            ) : (
              ''
            )
          )}
      </div>
    </div>
  );
};

export default CareerGrid;
