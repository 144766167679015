import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import TabGroup from '../components/TabGroup';
import SubscribeBlog from '../components/SubscribeBlog';
import Layout from '../components/Layout';
import blogBanner from '../images/blogBanner.png';
import '../styles/global.scss';

const Blog = ({ data }) => {
  const type = data.contentfulBlogPageLandingPage.type;

  return (
    <>
      <Layout isWithoutHero={true}>
        <SEO
          title='Blog'
          description={data.contentfulBlogPageLandingPage.title}
          bannerImage={blogBanner}
          image={blogBanner}
        />
        {/* <Hero
          title={data.contentfulBlogPageLandingPage.title}
          subtitle={data.contentfulBlogPageLandingPage.subtitle}
          bgImage={data.contentfulBlogPageLandingPage.hero.file.url}
          isProducts={false}
          isHomePage={true}
          isLetsTalk={false}
          isFull='is-fullheight-with-navbar'
          isCareers={false}
          isBlog={true}
        /> */}
        <div
          style={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginTop: '150px' }} className='handbook-heading'>
            Building A Knowledge Centre
          </div>
          <div className='handbook-description' style={{ textAlign: 'center' }}>
            Collection of our learnings, insights & milestones along the journey
            of creating global products
          </div>
        </div>
        <TabGroup type={type} isCareers={false} />
        <SubscribeBlog />
      </Layout>
    </>
  );
};

export default Blog;
export const data = graphql`
  {
    contentfulBlogPageLandingPage {
      title
      type
      subtitle
      hero {
        file {
          url
        }
      }
    }
  }
`;
