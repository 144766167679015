// @ts-nocheck
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { format } from 'date-fns';

const BlogCard = props => (
  <Link
    to={`/resources/${props.node.slug}/`}
    style={{ textDecoration: 'none' }}
  >
    <div
      className='card blog-card-mediamatch'
      style={{
        borderRadius: '10px',
        height: '100%',
      }}
    >
      {' '}
      <div className='card-image'>
        {props.node.featuredImage && (
          <div className='card-image-container'>
            <center>
              <img
                className='featured'
                style={{
                  backgroundColor: props.node.color,
                  height: '40vh',
                  width: '100%',
                  objectFit: 'contain',
                }}
                src={props.node.featuredImage.file.url}
                alt={props.node.title}
              />
            </center>
          </div>
        )}
        <div className='card-content'>
          <div className='media-content'>
            {/* Removing Types for now */}
            <p className='card-type is-centered' style={{ width: '100%' }}>
              {props.node.type}
            </p>
            <p className='card-title is-4'> {props.node.title}</p>
            <p className='card-author'>
              {format(new Date(props.node.publishedDate), 'do MMM, yyyy')} |{' '}
              {props.node.body?.childMarkdownRemark?.timeToRead} min read
            </p>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

const BlogGrid = ({ typeToShow }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPosting(
          filter: {}
          sort: { fields: publishedDate, order: DESC }
        ) {
          edges {
            node {
              type
              title
              id
              slug
              color
              publishedDate

              featuredImage {
                file {
                  url
                }
              }
              body {
                childMarkdownRemark {
                  timeToRead
                }
              }
              display
            }
          }
        }
      }
    `
  );

  return (
    <section className='section'>
      <div className='columns is-multiline blog-grid-card'>
        {data.allContentfulBlogPosting.edges &&
          data.allContentfulBlogPosting.edges.map(edge =>
            (typeToShow === 'All' || edge.node.type === typeToShow) && edge.node.display!==false ? (
              <div className='column is-4-fullhd  is-6-desktop '>
                <BlogCard key={edge.node.id} node={edge.node} />
              </div>
            ) : null
          )}
      </div>
    </section>
  );
};

export default BlogGrid;
