import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import BlogGrid from './BlogGrid';
import CareerGrid from './CareerGrid';

const Tab = styled.button`
  font-size: clamp(0.6em, 2vw, 1.2em);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.97px;
  color: #111;
  text-transform: uppercase;
  font-family: 'Open Sans';
  margin: 35px clamp(0.15em, 2vw, 1.5em) 30px clamp(0.15em, 2vw, 1.5em);
  cursor: pointer;
  opacity: 0.3;
  background: white;
  border: 0;
  outline: 0;
  transition: 0.4s ease;

  ${({ active }) =>
    active &&
    `
    border-bottom: 4px solid ;
    border-radius:5px;
    border-bottom-color:  #009eff;
    opacity: 1;
  `}
  :hover {
    opacity: 1;
    transition: 0.4s ease;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  max-width: 100%;
`;
const TabGroup = ({ type, isCareers }) => {
  const [choice, setChoice] = React.useState('All');
  const [active, setActive] = useState(type[0]);

  const openThatPage = type => {
    setActive(type);
    setChoice(type);
  };

  return (
    <>
      <ButtonGroup style={{ justifyContent: 'center' }}>
        {type &&
          type.map(type => (
            <Tab
              key={type}
              active={active === type}
              onClick={() => openThatPage(type)}
            >
              {type}
            </Tab>
          ))}{' '}
      </ButtonGroup>{' '}
      {/* WIP Search */}
      {/* <Search indices={searchIndices} /> */}
      {isCareers ? (
        <CareerGrid typeToShow={choice} />
      ) : (
        <BlogGrid typeToShow={choice} />
      )}
    </>
  );
};

TabGroup.defaultProps = {
  isCareers: false,
};
TabGroup.propTypes = {
  type: PropTypes.shape({
    map: PropTypes.func,
  }),
  isCareers: PropTypes.bool,
};

export default TabGroup;
